import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default function PrimaryButton({ className = '', disabled, children, ...props }) {
    return (
        <button
            {...props}
            className={
                `submit_btn ${
                    disabled && 'loading'
                } ` + className
            }
            disabled={disabled}
        >
            <span className="submit_btn_text">{children}</span>

        </button>
    );
}
